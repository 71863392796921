import React from 'react'
import './About.css'
//import ME from '../../assets/me2.png'
import ME from '../../assets/me-about.png';
import {FaAward} from 'react-icons/fa';
import {FiUsers} from 'react-icons/fi';
import { IoMdSchool } from 'react-icons/io';
import { IoIosFootball } from 'react-icons/io';
import {FaAddressCard} from 'react-icons/fa';
import {FaStarOfLife} from 'react-icons/fa';


import { icons } from 'react-icons'


const About = () => {
  return (
    <section id='About'>
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
{/* =========================CARDS======================= */}
        <div className="about__content">
          <div className="about__maincards">
            
            <article className='about__card'>
                <FaAward className='about__icon'/>
                <h4>Experience</h4>
                <small>12 Years Overall</small>
                <br />
                <small>4+ years as Engineering Manager</small>
            </article>

            <article className='about__card'>
                <FiUsers className='about__icon'/>
                <h4>Clients</h4>
                <small>15+ Projects Delivered Worldwide</small>
            </article>
            
            <article className='about__card'>
              <IoMdSchool className='about__icon'/>
              <h4>Education</h4>
              <a href='#' onClick={(e) => { e.preventDefault(); window.open(require('../About/BCA.jpg'), '_blank'); }}>
                <small>Bachelors Degree in Computer Applications</small>
              </a>
              <img src={require('../About/BCA.jpg')} alt='Image description' style={{display: 'none'}} onContextMenu={(e) => { e.preventDefault(); }} crossOrigin='anonymous'/>
           </article>


            <article className='about__card'>
                <FaAddressCard className='about__icon'/>
                <h4>Address</h4>
                <small> Katraj, Pune Maharashtra - India</small>
            </article>

            <article className='about__card'>
                <IoIosFootball className='about__icon'/>
                <h4>Activities</h4>
                <small> <strong>
                  <a href= "https://www.facebook.com/LiverpoolPune">Co-Admin of Liverpool Pune </a> </strong> 
                </small>
            </article>

            <article className='about__card'>
                <FaStarOfLife className='about__icon'/>
                <h4>Hobbies</h4>
                <small> Playing Football, Watching Podcasts</small>
            </article>

          </div> 
            <p> </p>         
            <a href='#Contact' className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>

    </section>
  )
}

export default About