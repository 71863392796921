import React from 'react'
import './Portfolio.css'
import IMG1 from '../../assets/portfolio/rdex.jpg'
import IMG2 from '../../assets/portfolio/lfc-quiz.jpg'
import IMG3 from '../../assets/portfolio/daddy.jpg'
import IMG4 from '../../assets/portfolio/nft-stable.jpg'
import IMG5 from '../../assets/portfolio/rkart.jpg'
import IMG6 from '../../assets/portfolio/lfc-news.jpg'

// DO NOT USE IMAGES IN PRODUCTION
//Map Functions
const data=[
  {
    id:4,
    image:IMG4,
    title:'NFT Generator for Stable Diffusion 2.1',
    code:'https://github.com/RackeshKamble/NFT-Generator-for-Stable-Diffusion-2.1',
    demo :'https://nft-generator.rakeshkamble.com/'
  },
  {
    id:5,
    image:IMG5,
    title:'RKart - Web3 Shopping Kart ',
    code:'https://github.com/RackeshKamble/RKart',
    demo :'https://rkart.rakeshkamble.com/'
  },
  {
    id:1,
    image:IMG2,
    title:'Liverpool FC Quiz App ',
    code:'https://codesandbox.io/s/lfc-quiz-pvdl2t',
    demo :'https://pvdl2t.csb.app/quiz'
  },  
  {
    id:3,
    image:IMG3,
    title:'Domain-Daddy - Web3 Domain Buyer',
    code:'https://github.com/RackeshKamble/Domain-Daddy',
    demo :'https://domain-daddy.rakeshkamble.com/'
  }, 
  {
    id:6,
    image:IMG6,
    title:'Liverpool FC News App',
    code:'https://codesandbox.io/s/lfc-news-app-wyc328',
    demo : 'https://wyc328.csb.app/'
  },
  {
    id:2,
    image:IMG1,
    title:'Decentralized Crypto Currency Exchange',
    code:'https://github.com/RackeshKamble/BlockChain-Development-RDex.git',
    demo :'https://rdex.on.fleek.co'
  }
]

const Portfolio = () => {
  return (
    <section id='Portfolio'>      
      <h2>Portfolio</h2>
      <h5>My Recent Projects</h5>
      <br></br>
      <div className="container portfolio__container">
        {
          data.map(({id,image,title,code,demo})=>{
            return(
                    <article key={id} className='portfolio__item'>
                      <div className="portfolio__item-image">
                        <img src={image} alt={title}/>
                      </div>
                      <h3>{title}</h3>
                      <div className="portfolio__item-cta">
                        <a href={code} className='btn' target='_blank' >Codebase</a>
                        <a href={demo} className='btn btn-primary' target='_blank' >Demo</a>
                      </div>
                    </article>
                  )
          })
        }     
        
      </div>
      </section>
  )
}

export default Portfolio