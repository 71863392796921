import React, { useRef } from 'react'
import './Contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs.sendForm('service_06v4ccf', 'template_xwjnhvc', form.current, 'wOrSEFi4DLtjM2t8D')
      // .then((result) => {
      //     console.log(result.text);
      // }, (error) => {
      //     console.log(error.text);
      // });
      e.target.reset();
  };
  

  return (
    <section id='Contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineEmail/>
            <h4>Email</h4>
            <h5>rakeshkamble@hotmail.com</h5>
            <a href="mailto:rakeshkamble@hotmail.com">Send Email</a>
          </article>

          <article className='contact__option'>
            <RiMessengerLine/>
            <h4>Messenger</h4>
            <h5>Rackesh Kamble</h5>
            <a href="https://m.me/RackTheBlackMan">Send a Message</a>
          </article>

          <article className='contact__option'>
            <BsWhatsapp/>
            <h4>WhatsApp</h4>
            <h5></h5>
            {/* <a href="https://api.whatsapp.com/send?phone=+919561368665">Send WhatsApp</a> */}
            <a href="https://wa.me/+919561368665">Send WhatsApp</a>
            {/* https://wa.me/+919561368665 */}
          </article>

        </div>

        {/* END OF CONTACT */}
        
        <form  ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name='message' rows="7" placeholder='Your Message' required />
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact