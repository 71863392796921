import React from 'react'
import './Nav.css'
import {ImHome} from 'react-icons/im'
import { SiAboutdotme } from 'react-icons/si'
import { GiSkills } from 'react-icons/gi';
import { GrProjects } from 'react-icons/gr'
import { SiOnlyoffice } from 'react-icons/si';
import { AiFillSafetyCertificate } from 'react-icons/ai';


import { MdContactMail } from 'react-icons/md'
import { useState } from 'react'

const Nav = () => {
  //hooks used 
  const [activeNav,setActiveNav] = useState("#");

  return (
    <nav>
      
      <a href='#' onClick={() =>setActiveNav("#") } className={activeNav === "#" ? "active" : ""}><ImHome/> </a>
      <a href="#About" onClick={() => setActiveNav("About")} className={activeNav === "About" ? "active" : ""}> <SiAboutdotme/></a>
      <a href="#Skills" onClick={() => setActiveNav("Skills")} className={activeNav === "Skills" ? "active" : ""}> <GiSkills/></a>
      <a href="#Experience" onClick={() => setActiveNav("Experience")} className={activeNav === "Experience" ? "active" : ""}> <SiOnlyoffice/></a>
      <a href="#Certifications" onClick={() => setActiveNav("Certifications")} className={activeNav === "Certifications" ? "active" : ""}> <AiFillSafetyCertificate/></a>
      <a href="#Portfolio" onClick={() => setActiveNav("Portfolio")} className={activeNav === "Portfolio" ? "active" : ""}> <GrProjects/></a>
      <a href="#Contact" onClick={() => setActiveNav("Contact")} className={activeNav === "Contact" ? "active" : ""}> <MdContactMail/></a>      
    </nav>
  )
}

export default Nav