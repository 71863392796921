// Call to Action
import React from 'react'
import CV from '../../assets/rkcv.pdf'
import './CTA.css';


const CTA = () => {
  return (
    <div className='cta'>
      
      {/* Pass # ID for Contact component as well as call btn class and btn hover class */}
      <a href="https://blogs.rakeshkamble.com/" className='btn btn-primary'>Rakesh's Blogs</a>
    
      {/* To download CV  use a href={CV} download  */}
      <a href={CV} download className='btn btn-prim'>Download CV</a>  

      {/* Pass # ID for Contact component as well as call btn class and btn hover class */}
      <a href="#Contact" className='btn btn-prim'>Let's Chat</a>
    </div>
  )

}

export default CTA