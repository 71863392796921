import React from 'react'
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { FiTwitter } from 'react-icons/fi';
import { FaYoutube } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { FiRss } from 'react-icons/fi';

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
    {/* Make sure react-icons are installed npm install react-icons --save*/}
    {/* https://react-icons.github.io/react-icons/search */}

      {/* LinkedIn */}
      <a href="https://www.linkedin.com/in/rakesh-kamble-026217a1/" target="_blank">
        <BsLinkedin />
      </a>

      {/* GitHub */}
      <a href="https://github.com/RackeshKamble/" target="_blank">
        <FaGithub />
      </a>

      {/* Twitter */}
      <a href="https://twitter.com/RackTheBlackMan" target="_blank">
        <FiTwitter />
      </a>

      {/* YouTube */}
      <a href="https://www.youtube.com/@racktheblackman" target="_blank">
        <FaYoutube />
      </a>

      {/* Instagram */}
      <a href="https://www.instagram.com/RackTheBlackMan/" target="_blank">
        <FaInstagram />
      </a>

      {/* Facebook */}
      <a href="https://www.facebook.com/RackTheBlackMan" target="_blank">
        <FaFacebookF />
      </a>

      {/* Blog */}
      <a href="https://blogs.rakeshkamble.com/" target="_blank">
        <FiRss />
      </a>
    </div>
  )
}

export default HeaderSocials