import React from 'react'
import './SKills.css'
import {BsPatchCheckFill} from 'react-icons/bs'

import { icons } from 'react-icons'
const SKills = () => {
  return (
    <section id='Skills'>
      {/* <h5>My SKill Sets </h5> */}
      <h2>My Skill Sets</h2>

      <div className="container  experience__container">

      {/* ===================Support and Consulting===================== */}
      <div className="experience__frontend">
        <h3>On Prem & Online Tech Stack</h3>
          <div className="experience__content">

          <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4><a href='https://blogs.rakeshkamble.com/category/sharepoint/'>SharePoint 2007/10/13/16/19</a></h4>
              </div>
            </article>
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/azure/'>Azure & Azure DevOps</a></h4>
                <h4></h4>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4><a href='https://blogs.rakeshkamble.com/category/other/'>Windows Server 2008/12/19</a></h4>
              </div>
            </article>                    
                        
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/sharepoint/'>SharePoint Online & O365</a></h4>
                <h4></h4>
              </div>
            </article>
            
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/other/'>Powershell & CLI</a></h4>
                <h4></h4>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/other/'>WordPress , Git & Chai</a></h4>
                <h4></h4>
              </div>
            </article>
            
          </div>
        </div>

{/* ===================Frontend Development===================== */}
        <div className="experience__frontend">
        <h3>Fronted Tech Stack</h3>
          <div className="experience__content">

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
                <h4><a href='https://blogs.rakeshkamble.com/category/web-2-0/html/'>HTML 5</a></h4>
              </div>
            </article>
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/web-2-0/css/'>CSS 3</a></h4>                
              </div>
            </article>
            
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/web-2-0/bootstrap/'>Bootstarap 4</a></h4>
                <h4></h4>
              </div>
            </article>
            
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/web-2-0/javascript/'>JavaScript</a></h4>
                <h4></h4>
              </div>
            </article>
            
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/web-2-0/jquery/'>JQuery</a></h4>
                <h4></h4>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/web-2-0/reactjs/'>ReactJS</a></h4>
                <h4></h4>
              </div>
            </article>
            
          </div>
        </div>
{/* ===================Backend Development===================== */}
        <div className="experience__backend">
        <h3>Backend Tech Stack</h3>
          <div className="experience__content">
          <article className='experience__details'>
            <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/web-2-0/nodejs/'>Node JS</a></h4>
                <h4></h4>
              </div>
            </article>
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/other/'>MongoDB</a></h4>
                <h4></h4>
              </div>
            </article>
            
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/web-2-0/javascript/'>JavaScript</a></h4>
                <h4></h4>
              </div>
            </article>
            
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/other/'>SQL Server</a></h4>
                <h4></h4>
              </div>
            </article>
            
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/sharepoint/'>SharePoint</a></h4>
                <h4></h4>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/node-js-with-express-js/'>Express JS</a></h4>
                <h4></h4>
              </div>
            </article>
        </div>
        </div>
{/* ===================Blockchain Development===================== */}
<div className="experience__backend">
        <h3>Blockchain Tech Stack</h3>
          <div className="experience__content">
          <article className='experience__details'>
            <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/category/web-3-0/solidity/'>Solidity</a></h4>
                <h4></h4>
              </div>
            </article>
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/web3-basics-with-solidity/'>Web3 JS</a></h4>
                <h4></h4>
              </div>
            </article>
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/non-fungible-token-nft/'>NFT</a></h4>
                <h4></h4>
              </div>
            </article>

            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/decentralized-applications-dapps/'>DApps</a></h4>
                <h4></h4>
              </div>
            </article>
            
            
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/hardhat/'>Hardhat</a></h4>
                <h4></h4>
              </div>
            </article>

            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon'/>
              <div>
              <h4><a href='https://blogs.rakeshkamble.com/infura/'>Infura</a></h4>
                <h4></h4>
              </div>
            </article>
            
            

           
        </div>
        </div>

      </div>
    </section>
  )
}

export default SKills