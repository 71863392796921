import React from 'react'
import './Experience.css'
import {BiCheck} from 'react-icons/bi'
import { icons } from 'react-icons'

const Experience = () => {

  //const Services = 'services';
  
  return (
    <section id='Experience'>
      {/* <h5>What I offer</h5> */}
      <h2>My Work Experience</h2>

      <div className="container services__container">
{/* =======================START OF Nitor InfoTech Pvt Ltd================ */}
        <article className='service'>
          
          <div className="service__head">
            <h3>Nitor InfoTech Pvt. Ltd.</h3>
            <h5>October 2014 – Till Date</h5>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>As an experienced Engineering Manager, I have
successfully managed and led teams on multiple
projects across different technology stacks, including
Microsoft SharePoint on-premises and online,
Windows Server, and Office 365, Azure and MERN
stack. </p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>I have worked with clients mostly in the USA, UK and
India, providing expert support and services in
SharePoint, Azure and IT infrastructure</p>            
            </li>
          

            <li>
              <BiCheck className='service__list-icon'/>
              <p>I have also successfully managed and led teams on
multiple projects, including SharePoint migration
projects, Azure Support and IT infrastructure projects. </p>            
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>As a Senior Business Analyst and Peer Product Manager, I provided valuable insights and guidance for developing high-quality ITSM products, while also spearheading project management for SharePoint, Azure, MERN stack and IT infrastructure support services to deliver successful outcomes for clients. </p>            
            </li>

            {/* <li>
              <BiCheck className='service__list-icon'/>
              <p>With my experience as a project manager, I have spearheaded the development and implementation of
support services and best practices for SharePoint,
Azure and IT infrastructure, ensuring project success
and delivering high-quality services to clients. </p>            
            </li> */}

          </ul>
        </article>
{/* =======================END OF Nitor InfoTech Pvt Ltd================ */}

{/* =============================================================================== */}


{/* =======================START OF Infosys================ */}
<article className='service'>
          
          <div className="service__head">
            <h3>Infosys</h3>
            <h5>Feb 2013 – Oct 2014</h5>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>I worked as a SharePoint Farm Administrator and Windows Administrator for two US-based clients.</p>            
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>As part of my role, I was responsible for SharePoint Farm Administration, developing, testing, and deploying solutions to support business processes and related SharePoint-based functionality.</p>            
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>I also had expertise in server maintenance, including
setup and configuration of SharePoint Services, as
well as migration procedures and issue resolution.</p>            
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Additionally, I have experience in SharePoint 2007,
2010, and 2013.</p>            
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>With my experience in project leadership and client
requirements gathering, I have provided valuable
insights to ensure project success and deliver highquality services to clients.</p>            
            </li>

            {/* <li>
              <BiCheck className='service__list-icon'/>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>            
            </li> */}
          </ul>
        </article>
{/* =======================END OF Infosys================ */}



{/* =======================START OF Mphasis and HP Company================ */}
<article className='service'>
          
          <div className="service__head">
            <h3>Mphasis and HP Company</h3>
            <h5>August 2010 – July 2012</h5>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>As an IT Support Specialist, I have successfully
managed critical incidents and provided on-call
support for configuration and application errors for
SharePoint 2007 and Windows Server. </p>            
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>I built expertise in SharePoint administration,
including user group permissions, content
management, navigation, and search engine
administration. </p>            
            </li>

            
            <li>
              <BiCheck className='service__list-icon'/>
              <p>I developed a thorough understanding of Managed Metadata, Application services, Taxonomy, Client
Object Models, Master pages, and Page layout</p>            
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Prior to this, I was, responsible for selling and
providing on-call support to US insurance service
providers. </p>            
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Later, I worked as a Windows Server L1/L2/L3
Support Engineer and then as a Microsoft SharePoint
2007 Administrator.
</p>            
            </li>


            {/* <li>
              <BiCheck className='service__list-icon'/>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>            
            </li> */}
          </ul>
        </article>
{/* =======================END OF Mphasis and HP Company================ */}

{/* =============================================================================== */}


{/* =============================================================================== */}




{/* =======================START OF NEW ERA INFOTECH PVT LTD================ */}
<article className='service'>
          
          <div className="service__head">
            <h3>New Era InfoTech Pvt. Ltd.</h3>
            <h5>September 2006 – May 2007</h5>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>I began my career as a Call Center Executive, where I
was responsible for providing backend support for
client software hosted on Windows Server 2003</p>            
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>I provided on-call and email support for technical
queries related to the client software and led a team
of four people for a quarter</p>            
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>In this role, I handled calls related to technical queries
of TomoGas software, a LPG gas booking system via
telephone, as well as provided general information on
LPG outlets and petrol units of HP, BP, and IOC</p>            
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Through my work as a Call Center Executive, I
developed strong communication and problem-solving
skills, as well as experience in providing technical
support.</p>            
            </li>

          </ul>
        </article>
{/* =======================END OF NEW ERA INFOTECH PVT LTD================ */}
      </div> 
    </section>
  )
}
{/* =============================================================================== */}

export default Experience