import React from 'react'
import './Header.css'
import CTA from './CTA'
//import ME from '../../assets/me-about.png'
import ME from '../../assets/RK.png'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>  
      <div className="container header__container">
        <h4>Hello I'm</h4>
        <h1>Rakesh Kamble</h1>
        {/* <h1>SITE IS UNDER DEVELOPMENT PAHSE RIGHT NOW</h1> */}
        <h5 className='text-light'><strong>Enginnering Manager, Project Manager and Product Owner <br></br> SharePoint , Azure & O365 Admin. <br></br>Self Taught Blockchain and MERN stack Developer</strong></h5>
        <CTA/>
        <HeaderSocials/>
        <br></br>
        <div className='me'>
        
          <img src={ME} alt='me' />
          
        </div>

        <a href='#Contact' className='scroll__down'>Scroll Down</a>
        
      </div>
    </header>
  )
}

export default Header