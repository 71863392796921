import React from 'react';
import './Certifications.css'
import AZURE from '../../assets/certifications/AZURE.png'
import ITIL from '../../assets/certifications/ITIL.png'
import MCP from '../../assets/certifications/MCP.png'
import MCTS from '../../assets/certifications/MCTS.png'
import MCITP from '../../assets/certifications/MCITP.png'

// import Swiper core and required modules
import { Pagination,Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data =[
  {
    avatar:AZURE,
    name:'Microsoft Certified Azure Administrator Associate'
  },
  {
    avatar:ITIL,
    name:'ITIL® 4 Foundation - IT Service Management'
  },
  {
    avatar:MCP,
    name:'Microsoft Certified Professional SharePoint 2013'
  },
  {
    avatar:MCTS,
    name:'Microsoft Certified Technology Specialist SharePoint 2013'
  },  
  {
    avatar:MCITP,
    name:'Microsoft Certified IT Professional SharePoint 2013'
  }
];

const Certifications = () => {;
    
  return (
    <section id='Certifications'>
      {/* <h5>Review From Clients</h5> */}
      <h2>My Certifications</h2>
      <h5>Swipe Right</h5>
      <br></br>
      
      <Swiper className="container testimonials__container"
      modules={[Pagination]} 
      spaceBetween={15} 
      slidesPerView={2}   
      loop = {true}
      autoplay = {{ delay: 1000, disableOnInteraction: true}}
      pagination={{ clickable: true }}     
      >
        {
          //Index is used as we don't have an unique key for articles tag
          data.map(({avatar,name,review},index) =>{
            return(
              <SwiperSlide key={index} className='testimonial'>
                <div className="client__avatar">
                  <img src={avatar}  />
                </div>
                <h4 className='client__name'>{name}</h4>
                 {/* <small className='client__review'>{review}</small> */}
              </SwiperSlide>
                  );
          })
        }
      </Swiper>
    </section>
  )
};


export default Certifications;