import React from 'react'
import './Footer.css'
import {FaFacebookF} from 'react-icons/fa'
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';
import { FiTwitter } from 'react-icons/fi';
import { FaYoutube } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FiRss } from 'react-icons/fi';
const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>RAKESH KAMBLE</a>

      <ul className='permalinks'>
        <li><a href='#About'>About</a></li>
        <li><a href='#Skills'>Skills</a></li>
        <li><a href='#Experience'>Experience</a></li>
        <li><a href='#Certifications'>Certifications</a></li>
        <li><a href='#Portfolio'>Portfolio</a></li>
        <li><a href='#Contact'>Contact</a></li>
      </ul>

      <div className="footer__socials">
        {/* <a href='https://facebook.com'> <FaFacebookF/> </a>
        <a href='https://twitter.com'> <FiTwitter/> </a>
        <a href='https://instagram.com'> <FiInstagram/> </a> */}

        {/* LinkedIn */}
      <a href="https://www.linkedin.com/in/rakesh-kamble-026217a1/" target="_blank">
        <BsLinkedin />
      </a>

      {/* GitHub */}
      <a href="https://github.com/RackeshKamble/" target="_blank">
        <FaGithub />
      </a>

      {/* Twitter */}
      <a href="https://twitter.com/RackTheBlackMan" target="_blank">
        <FiTwitter />
      </a>

      {/* YouTube */}
      <a href="https://www.youtube.com/@racktheblackman" target="_blank">
        <FaYoutube />
      </a>

      {/* Instagram */}
      <a href="https://www.instagram.com/RackTheBlackMan/" target="_blank">
        <FaInstagram />
      </a>

      {/* Facebook */}
      <a href="https://www.facebook.com/RackTheBlackMan" target="_blank">
        <FaFacebookF />
      </a>

      {/* Blog */}
      <a href="https://blogs.rakeshkamble.com/" target="_blank">
        <FiRss />
      </a>
      </div>

      <div className="footer__copyright">
        <strong>&copy; RAKESH KAMBLE. All Rights Reserved.</strong>
      </div>

    </footer>
  )
}

export default Footer